import React from "react";
import { motion } from "framer-motion";
import { images } from "../actions/customFn";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DomainNotFound = () => {
  const { t } = useTranslation();

  return ( 
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <div className="div-404-new">
          <div className="container">
            <div className="images-page-unknow">
              <img src={images["404image.png"]} alt="" />
            </div>

            <div className="page-4content">
              <h2> {t("_Looks_like_you’re_lost")}</h2>
              <p>{t("_The_domain_youre_looking_for_does_not_seem_too_exist")}</p>

              <NavLink to={process.env.REACT_APP_PRODUCTION_LINK}> {t("_Go_To_webiste")}</NavLink>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default DomainNotFound;
