/* global google */
import React, { useEffect, useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Circle,
} from "react-google-maps";
import { useSelector } from "react-redux";
import { getLocationInfo } from "../actions/customFn";
// import customMarker from "../assets/images/google_marker.png"; // Google map custom marker
import customMarker from "../assets/images/marker.svg";

const Map = (props) => {
  const homeData = useSelector((state) => state.home);
  const [googleKey, setGoogleKey] = useState("");

  useEffect(() => {
    if (homeData) {
      setGoogleKey(homeData?.settings?.google_key);
    }
  }, [homeData]);

  const circle = {
    radius: 1000,
    options: {
      strokeColor: "#ff00004f",
      fillColor: "#ff00004f",
    },
  };

  const handleMapClick = (event, index, key) => {
    props.setIsDragged(true);

    getLocationInfo(event.latLng.lat(), event.latLng.lng(), googleKey)
      .then((locationInfo) => {
        const place = {
          label: locationInfo.label,
          location: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
        };
        if (key == "ticket_redemption") {
          props.handleGoogleAddress(place, index);
        } else {
          props.handleGoogleAddress(place, key, index, "true");
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {props.places && props.mapName != undefined && (
        <GoogleMap
          zoom={props.zoom}
          // center={props.center}
          onBoundsChanged={props.onBoundsChanged}
          ref={props.onMapMounted}
        >
          {props.mapName == "multiple" ? (
            props.places.multiple.map((val, index) => {
              return (
                // val.hotel_location_obj != "" &&
                <div key={index}>
                  <Marker
                    position={{
                      lat: val.hotel_latitude,
                      lng: val.hotel_longitude,
                    }}
                    // position={markerPosition != "" ? markerPosition : {
                    //     lat: val.hotel_latitude,
                    //     lng: val.hotel_longitude
                    // }}
                    draggable={true}
                    onDragEnd={(event) => handleMapClick(event, index, "hotel")}
                    icon={{ url: customMarker }}
                  />
                  <Circle
                    center={{
                      lat: val.hotel_latitude,
                      lng: val.hotel_longitude,
                    }}
                    // center={center != "" ? center : {
                    //     lat: val.hotel_latitude,
                    //     lng: val.hotel_longitude
                    // }}
                    radius={
                      val.hotel_radius_type == "km"
                        ? parseFloat(val.hotel_radius) * 1000
                        : parseFloat(val.hotel_radius) * 1609.34
                    }
                    options={circle.options}
                  />
                </div>
              );
            })
          ) : props.mapName == "end_point" ? (
            props.places.multiple.map((val, index) => {
              return (
                // val.end_point_location_obj != "" &&
                <div key={index}>
                  <Marker
                    position={{
                      lat: parseFloat(val.end_point_latitude),
                      lng: parseFloat(val.end_point_longitude),
                    }}
                    draggable={true}
                    onDragEnd={(event) =>
                      handleMapClick(event, index, "end_point")
                    }
                    icon={{ url: customMarker }}
                  />
                </div>
              );
            })
          ) : props.mapName == "meeting_point" ? (
            props.places.multiple.map((val, index) => {
              return (
                // val.meeting_point_location_obj != "" &&
                <div key={index}>
                  <Marker
                    position={{
                      lat: val.meeting_point_latitude,
                      lng: val.meeting_point_longitude,
                    }}
                    draggable={true}
                    onDragEnd={(event) =>
                      handleMapClick(event, index, "meeting_point")
                    }
                    icon={{ url: customMarker }}
                  />
                </div>
              );
            })
          ) : props.mapName == "ticket_redemption" ? (
            props.places.multiple.map((val, index) => {
              return (
                // val.redemption_location_obj != "" &&
                <div key={index}>
                  <Marker
                    position={{
                      lat: val.redemption_latitude,
                      lng: val.redemption_longitude,
                    }}
                    draggable={true}
                    onDragEnd={(event) =>
                      handleMapClick(event, index, "ticket_redemption")
                    }
                    icon={{ url: customMarker }}
                  />
                </div>
              );
            })
          ) : props.mapName == "attraction" ? (
            props.places.multiple.map((val, index) => {
              return (
                // val.location_obj != "" &&
                <div key={index}>
                  <Marker
                    position={{
                      lat: parseFloat(val.attraction_latitude),
                      lng: parseFloat(val.attraction_longitude),
                    }}
                    icon={{ url: customMarker }}
                  />
                </div>
              );
            })
          ) : (
            <Marker
              position={{
                lat: props.places.lat,
                lng: props.places.lng,
              }}
              icon={{ url: customMarker }}
            />
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default withScriptjs(withGoogleMap(Map));
