import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, successMsg } from "./customFn";

//   ===================API call for booking-history starts=================
export const getOrderDetails = (
  languageReducer,
  setOrderDetails,
  navigate,
  setIsLoading,
  id,
  page = '',
  SetLoginKey = "",
  setModalLogin = "",
  setRedirectingUrl = "",
  location = ""
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        order_id: id,
        page: page
      };

      const response = await axios.post("order-details", params);
      if (response.data.status === true) {
        setOrderDetails(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        errorMsg("Somthing went wrong...");
        navigate("/supplier-booking")
        // setTimeout(() => {
        //   setIsLoading(false);
        // }, 1000);

      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        // errorMsg(error.response.data.message);
        errorMsg("Please login first to proceed");
        navigate("/");
        if (SetLoginKey && setModalLogin) {
          SetLoginKey("login");
          setModalLogin(true);
          setRedirectingUrl(location.pathname);
        }
      }
    }
    // setTimeout(() => {
    //   setIsLoading(true);
    // }, 1000);
  };
};

// ===================API call for booking-history ends===================

// ===================order Cancel===================
export const cancelOrder = (
  data,
  navigate,
  setIsCancelled,
  setButtonDisable,
  setShowOrderCancelModel,
  resetForm
) => {
  return async (dispatch) => {
    try {
      // const currency = JSON.parse(localStorage.getItem("currency"));
      // const token = localStorage.getItem("_token");
      // const params = {
      //   user_id: localStorage.getItem("userId"),
      //   password: localStorage.getItem("password"),
      //   language: languageReducer.id,
      //   currency: currency.id,
      //   token: token,
      //   ...data
      // };

      const response = await axios.post("cancel-order", data);
      if (response.data.status === true) {
        successMsg(response.data.message);
        setIsCancelled((state) => !state);
        setButtonDisable(false);
        setShowOrderCancelModel(false);
        resetForm();
      } else {
        errorMsg(response.data.message);
        // setButtonDisable(false);
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
      setButtonDisable(false);
    }
    // setTimeout(() => {
    //   setIsLoading(true);
    // }, 1000);
  };
};

// ===================order cancel ===================
// ===================order Cancel Reason===================
export const cancelOrderReason = (
  cancelReasonQuery,
  languageReducer,
  navigate,
  setData
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...cancelReasonQuery
      };

      const response = await axios.post("cancel-order-reason", params);
      if (response.data.status === true) {
        setData(response.data.data)
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //   setIsLoading(true);
    // }, 1000);
  };
};
// ===================order cancel Reason===================

// ===================update pickup point starts===================
export const updatePickPointData = (
  data,
  languageReducer,
  navigate,
  meetingPointData,
  setMeetingPointData,
  index,setIsLocationUpdate,isLocationUpdate
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...data
      };

      const response = await axios.post("add-pickup-point", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        let newData = [...meetingPointData];
        newData[index].is_update = 1;
        setMeetingPointData(newData);
        setIsLocationUpdate(!isLocationUpdate)
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================update pickup point ends===================

// Get Last Cart Product

export const getLastCartProduct = (
  languageReducer,
  navigate,
  setIsLoading,
  setCartDetails
)=>{

  return async () => {
 
    
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        type:"last_product"
      };

      const response = await axios.post("cart-list", params);
      if (response.data.status === true) {
        // successMsg(response.data.message);
          
          setCartDetails(response.data.data);
          setIsLoading(false);
      } else {
        navigate("/cart");
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    
  };
}


// Change Order Date
export const asyncUpdateOrder = async (languageReducer, navigate, formData) => {
  try {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const token = localStorage.getItem("_token");
    const params = {
      ...formData,
      currency: currency, 
      token: token,
      user_id: localStorage.getItem("userId"),
      password: localStorage.getItem("password"),
      language: languageReducer.id,
    };
    const response = await axios.post("update-order", params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      errorMsg("Somthing went wrong...");
    }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
      navigate("/invalid-domain");
      window.history.pushState(null, "", "/invalid-domain");
    } else {
      errorMsg(error.response.data.message);
    }
  }
};


export const asyncGetEditOrderDate = async (languageReducer, navigate, formData) => {
  try {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const token = localStorage.getItem("_token");
    const params = {
      ...formData,
      currency: currency, 
      token: token,
      user_id: localStorage.getItem("userId"), 
      password: localStorage.getItem("password"),
      language: languageReducer.id,
    };
    const response = await axios.post("get_edit_order_dates", params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      errorMsg("Somthing went wrong...");
    } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
      navigate("/invalid-domain");
      window.history.pushState(null, "", "/invalid-domain");
    }else {
      errorMsg(error.response.data.message);
    }
  }
};


export const asyncGetEditOrderTime = async (languageReducer, navigate, formData) => {
  try {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const token = localStorage.getItem("_token");
    const params = {
      ...formData,
      currency: currency, 
      token: token,
      user_id: localStorage.getItem("userId"), 
      password: localStorage.getItem("password"),
      language: languageReducer.id,
    };
    const response = await axios.post("get_edit_order_dates_time", params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      errorMsg("Somthing went wrong...");
    }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
      navigate("/invalid-domain");
      window.history.pushState(null, "", "/invalid-domain");
    } else {
      errorMsg(error.response.data.message);
    }
  }
};

