import { Navigate } from "react-router-dom";
import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg } from "./customFn";

//   ===================API call for guide starts=================
export const getGuideData = (
  languageReducer,
  setGuideData,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const params = {
        language: languageReducer.id,
        currency: currency.id,
      };

      const response = await axios.post("guide", params);
      if (response.data.status === true) {
        setGuideData(response.data.data);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE){
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        alert(`${error.message}-${error.response.status}`);
      }
    }
    setTimeout(function () {
      setIsLoading(true);
    }, 1000);
  };
};

// ===================API call for guide ends===================
//   ===================API call for guide starts=================
export const getGuideList = (
  languageReducer,
  setGuideList,
  navigate,
  setPageCount,
  currentPage
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const params = {
        language: languageReducer.id,
        currency: currency.id,
        offset: currentPage,
      };

      const response = await axios.post("guide-list", params);
      if (response.data.status === true) {
        setGuideList(response.data.data);
        setPageCount(response.data.page_count);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        alert(`${error.message}-${error.response.status}`);
      }
    }
  };
};
//===================API call for guide ends===================

//===================Guide Details starts=================
export const getGuideDetails = (
  languageReducer,
  setGuideDetails,
  navigate,
  slug
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const params = {
        id: slug,
        language: languageReducer.id,
        currency: currency.id,
      };

      const response = await axios.post("guide-detail", params);
      if (response.data.status === true) {
        setGuideDetails(response.data.data);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        alert(`${error.message}-${error.response.status}`);
      }
    }
    // setTimeout(function () {
    //     setIsLoading(true);
    // }, 1000);
  };
};
// =================== Guide Details ends===================
