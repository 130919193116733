import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  AfiliateBanner,
  AfiliateSlider,
  Footer,
  GrowMedia,
  HeaderSticky,
} from "../components";
import { useSelector } from "react-redux";
import { asyncGetNewAffiliatePageDetails } from "../actions/pageAction";
import { images } from "../actions/customFn";

const AfiliatesNewPage = () => {
  const [affiliateNewData, setAffiliateNewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const languageReducer = useSelector((state) => state.languageReducer);
  // UseEffect to get AboutUsPage data on component mount or when dependencies change
  useEffect(() => {
    if (languageReducer) {
      try {
        asyncGetNewAffiliatePageDetails(
          setAffiliateNewData,
          setIsLoading,
          languageReducer
        );
      } catch (error) {
        console.error("Error fetching affiliate data:", error);
      }
    }
  }, [languageReducer]);
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <HeaderSticky />
        <AfiliateBanner
          afiliateBannerData={affiliateNewData}
          isLoading={isLoading}
        />
        <GrowMedia growMediaData={affiliateNewData} isLoading={isLoading} />
        <AfiliateSlider
          afiliateSliderData={affiliateNewData}
          isLoading={isLoading}
        />

        <div className="image_blur_div"></div>

        <div className="affiliate_page_bottom_iamges">
          <img src={images["affiliate_footer.png"]} alt="" />
        </div>
        <div className="">
          <div className="footer_topblur_div"></div>
        </div>
        <div className="remove_footer_shadow">
          <Footer />
        </div>
      </motion.div>
    </>
  );
};

export default AfiliatesNewPage;
