import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg } from "./customFn";

//   ===================API call for privacy policy starts=================
export const getPrivacyPolicy = (languageReducer, setPrivacyPolicy, navigate, setIsLoading) => {
    return async (dispatch) => {
        try {
            const currency = JSON.parse(localStorage.getItem("currency"));
            const params = {
                language: languageReducer.id,
                currency: currency.id
            };

            const response = await axios.post("privacy-policy", params);
            if (response.data.status === true) {
                setPrivacyPolicy(response.data.data)
                setTimeout(function () {
                    setIsLoading(true);
                }, 1000);
            } else {
                setTimeout(function () {
                    setIsLoading(true);
                }, 1000);
                errorMsg("Somthing went wrong...");
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
                navigate("/invalid-domain");
                window.history.pushState(null, "", "/invalid-domain");
              } else {
                alert(`${error.message}-${error.response.status}`);
            }
        }
        // setTimeout(function () {
        //     setIsLoading(true);
        // }, 1000);
    };
};

// ===================API call for privacy policy ends===================

//   ===================API call for Terms and Condition starts=================
export const getTermsAndCondition = (languageReducer, setTermsAndCondition, navigate, setIsLoading) => {
    return async (dispatch) => {
        try {
            const currency = JSON.parse(localStorage.getItem("currency"));
            const params = {
                language: languageReducer.id,
                currency: currency.id
            };

            const response = await axios.post("terms-condition", params);
            if (response.data.status === true) {
                setTermsAndCondition(response.data.data)
                setTimeout(function () {
                    setIsLoading(true);
                }, 1000);
            } else {
                setTimeout(function () {
                    setIsLoading(true);
                }, 1000);
                errorMsg("Somthing went wrong...");
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
                navigate("/invalid-domain");
                window.history.pushState(null, "", "/invalid-domain");
              } else {
                alert(`${error.message}-${error.response.status}`);
            }
        }
        // setTimeout(function () {
        //     setIsLoading(true);
        // }, 1000);
    };
};
// ===================API call for Terms and Condition ends===================


//   ===================API call for Partner Terms and Condition starts=================
export const getUserTermsAndCondition = (languageReducer, setTermsAndCondition, navigate, setIsLoading,user_type="") => {
    return async (dispatch) => {
        try {
            const currency = JSON.parse(localStorage.getItem("currency"));
            const params = {
                language: languageReducer.id,
                currency: currency.id,
                user_type: user_type,
            };

            const response = await axios.post("user-terms-condition", params);
            if (response.data.status === true) {
                setTermsAndCondition(response.data.data) 
                setTimeout(function () {
                    setIsLoading(true);
                }, 1000);
            } else {
                setTimeout(function () {
                    setIsLoading(true);
                }, 1000);
                errorMsg("Somthing went wrong...");
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
                navigate("/invalid-domain");
                window.history.pushState(null, "", "/invalid-domain");
              } else {
                alert(`${error.message}-${error.response.status}`);
            }
        }
        // setTimeout(function () {
        //     setIsLoading(true);
        // }, 1000);
    };
};
// ===================API call for Partner  Terms and Condition ends===================
