import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, readCookie, successMsg } from "./customFn";
import { setCartCount } from "./cartAction";

//   ===================API call to get cart items starts=================
export const placeOrder = (
  languageReducer,
  formData,
  navigate,
  setIsLoading,
  paymentInfo,
  setPaymentLoader
) => {
  return async (dispatch) => {
    try {
      const formdata = new FormData();
      const currency = JSON.parse(localStorage.getItem("currency"));
      // const affilliate_code = localStorage.getItem("affiliate_code");
      let affilliate_code = readCookie("affilliate_code");

      formdata.append("user_id", localStorage.getItem("userId"));
      formdata.append("password", localStorage.getItem("password"));
      formdata.append("language", languageReducer.id);
      formdata.append("currency", currency.id);
      formdata.append("token", localStorage.getItem("_token"));
      formdata.append("first_name", formData.first_name);
      formdata.append("last_name", formData.last_name);
      formdata.append("email", formData.email);
      formdata.append("phone", formData.phone);
      formdata.append("country", formData.country);
      formdata.append("state", formData.state);
      formdata.append("city", formData.city);
      formdata.append("zipcode", formData.zipcode);
      formdata.append("address", formData.address);
      formdata.append("coupon", formData.coupon);
      formdata.append("payment_method", formData.payment_method);

      if (paymentInfo.stripe_token !== undefined) {
        formdata.append("stripe_token", paymentInfo.stripe_token);
      } else if (paymentInfo.paypal_details !== undefined) {
        formdata.append(
          "paypal_details",
          JSON.stringify(paymentInfo.paypal_details)
        );
      }

      if (affilliate_code) {
        formdata.append("affilliate_code", affilliate_code);
      }

      const response = await axios.post("/checkout", formdata);
      if (response.data.status === true) {
        successMsg(response.data.message);
        dispatch(setCartCount(0));
        // navigate(`/order-detail/${response.data.data.order_id}`);
        navigate(`/thank-you`, {
          state: { orderId: response.data.data.order_id },
        });

        setTimeout(() => {
          setIsLoading(true);
        }, 1000);
      } else {
        setTimeout(() => {
          setIsLoading(true);
        }, 1000);
        setPaymentLoader(false);
        errorMsg(response.data.message);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else if(error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE){
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //   setIsLoading(true);
    // }, 1000);
  };
};
// ===================API call to get cart items ends===================
