import axios from ".././axios";
import { errorMsg, successMsg } from "./customFn";
// import { asyncGetLogout } from "./loginAction";

// ===================Add To Wishlist =================
export const asyncAddToWishlist = (
  id,
  languageReducer,
  setIsWishlished,
  isWishlished,
  navigate,
  location,
  setWishLishedLoader = ""
) => {
  const currency = JSON.parse(localStorage.getItem("currency"));

  const formData = {
    user_id: localStorage.getItem("userId"),
    password: localStorage.getItem("password"),
    product_id: id,
    currency: currency.id,
    language: languageReducer.id
  };

  return (dispatch) => {
    const url = "/add_wishlist";
    axios
      .post(url, formData)
      .then((response) => {
        const data = response.data;
        if (data.status) {
          successMsg(data.message);
        } else {
          errorMsg(data.message);
        }
        if (setWishLishedLoader) {
          setWishLishedLoader(false);
        }
        isWishlished === 1 ? setIsWishlished(0) : setIsWishlished(1)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //   dispatch(asyncGetLogout());
          errorMsg("Somthing went wrong...");
        }else if (
          err?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
        ) {
          navigate("/invalid-domain");
          window.history.pushState(null, "", "/invalid-domain");
        } else {
          navigate("/something-went-wrong");
          window.history.pushState(null, "", location.pathname);
        }
      });
  };
};
// ==================Add To Wishlist===================

// ===================Remove from Wishlist =================
export const removeFromWishlist = (
  id,
  newId,
  languageReducer,
  navigate,
  location,
  setWishLishedLoader,
  setWishLishedArray,
  wishLishedArray,
  setIsWishlished = "",
  isWishlished = "",
  activity_id = "",
  setActivityRemoved = ""
) => {
  const currency = JSON.parse(localStorage.getItem("currency"));

  const formData = {
    user_id: localStorage.getItem("userId"),
    password: localStorage.getItem("password"),
    product_id: id,
    currency: currency.id,
    language: languageReducer.id,
    activity_id: activity_id
  };

  return (dispatch) => {
    const url = "/remove_wishlist";
    axios
      .post(url, formData)
      .then((response) => {
        const data = response.data;
        if (data.status) {
          successMsg(data.message);
          if (wishLishedArray) {                                                   //case: wishlist from home page
            setWishLishedArray(wishLishedArray.filter((item) => item !== newId));
          }

          if (isWishlished && setIsWishlished) {                                   //case: wishlist from culture detail page
            isWishlished === 1 ? setIsWishlished(0) : setIsWishlished(1)
          }

          if (setActivityRemoved) {
            setActivityRemoved((status) => !status);
          }
        } else {
          errorMsg(data.message);
        }
        if (setWishLishedLoader) {
          setWishLishedLoader(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          errorMsg("Somthing went wrong...");
        }else if (
          err?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
        ) {
          navigate("/invalid-domain");
          window.history.pushState(null, "", "/invalid-domain");
        } else {
          navigate("/something-went-wrong");
          window.history.pushState(null, "", location.pathname);
        }
      });
  };
};
// =================Remove from Wishlist===================

// ===================Get Wishlist =================
export const getWishlist = (setWishListData, languageReducer, navigate, location, setRecordStatus, setIsLoading, activityId, setActivityInfo) => {
  const currency = JSON.parse(localStorage.getItem("currency"));
  const formData = {
    user_id: localStorage.getItem("userId"),
    password: localStorage.getItem("password"),
    currency: currency.id,
    language: languageReducer.id,
    activity_id: activityId
  };

  return (dispatch) => {
    const url = "/get-wishlist";
    axios
      .post(url, formData)
      .then((response) => {
        const data = response.data;
        if (data.status) {
          setWishListData(data.data);
          setActivityInfo((prev) => ({ ...prev, "activity_count": data.data.length }))
          setRecordStatus(true);
          setTimeout(function () {
            setIsLoading(false);
          }, 1000);
        } else {
          setTimeout(function () {
            setIsLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //   dispatch(asyncGetLogout());
          errorMsg("Somthing went wrong...");
        }else if (
          err?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
        ) {
          navigate("/invalid-domain");
          window.history.pushState(null, "", "/invalid-domain");
        } else {
          navigate("/something-went-wrong");
          window.history.pushState(null, "", location.pathname);
        }
      });

    // setTimeout(function () {
    //   setIsLoading(false);
    // }, 1000);
  };
};
// ==================Get Wishlist===================


export const getWishlistActivityList = (setActivityList, languageReducer, navigate, location, setOpenWishlistModel, setOpenNewListModel) => {
  const currency = JSON.parse(localStorage.getItem("currency"));
  const formData = {
    user_id: localStorage.getItem("userId"),
    password: localStorage.getItem("password"),
    currency: currency.id,
    language: languageReducer.id
  };

  return (dispatch) => {
    const url = "/get-wishlist-activity-list";
    axios
      .post(url, formData)
      .then((response) => {
        const data = response.data;
        if (data.status) {
          setActivityList(data.data);
          if (data.data.length > 0) {
            setOpenWishlistModel != "" &&
              setOpenWishlistModel(true);
          } else {
            setOpenNewListModel != "" &&
              setOpenNewListModel(true)
          }
        } else {
          errorMsg("Somthing went wrong...")
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //   dispatch(asyncGetLogout());
          errorMsg("Somthing went wrong...");
        }else if (
          err?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
        ) {
          navigate("/invalid-domain");
          window.history.pushState(null, "", "/invalid-domain");
        } else {
          navigate("/something-went-wrong");
          window.history.pushState(null, "", location.pathname);
        }
      });

    // setTimeout(function () {
    //   setIsLoading(false);
    // }, 1000);
  };

};
export const addWishlistActivity = (
  data,
  languageReducer,
  navigate,
  location,
  closeModel,
  wishlistParameter,
  setWishLishedArray = "",
  setIsWishlished = "",
  isWishlished = ""
) => {

  const currency = JSON.parse(localStorage.getItem("currency"));
  const formData = {
    user_id: localStorage.getItem("userId"),
    password: localStorage.getItem("password"),
    currency: currency.id,
    language: languageReducer.id,
    ...data
  };

  return (dispatch) => {
    const url = "/add_wishlist_activity";
    axios
      .post(url, formData)
      .then((response) => {
        const data = response.data;
        if (data.status) {
          successMsg(data.message);
          closeModel();

          if (setWishLishedArray) {
            setWishLishedArray((state) => [...state, wishlistParameter.newId]);
          }

          if ((isWishlished || isWishlished == 0) && setIsWishlished != '') {        //case: wishlist from culture detail page
            isWishlished === 1 ? setIsWishlished(0) : setIsWishlished(1)
          }
        } else {
          errorMsg(data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // dispatch(asyncGetLogout());
          errorMsg("Somthing went wrong...");
        }else if (
          err?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
        ) {
          navigate("/invalid-domain");
          window.history.pushState(null, "", "/invalid-domain");
        } else {
          navigate("/something-went-wrong");
          window.history.pushState(null, "", location.pathname);
        }
      });

    // setTimeout(function () {
    //   setIsLoading(false);
    // }, 1000);
  };
};
