import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, successMsg } from "./customFn";

export const getMapData = (
    languageReducer,
    setMapData,
    navigate,
    setIsLoading,
) => {
    return async (dispatch) => {
        try {
            const currency = JSON.parse(localStorage.getItem("currency"));
            const token = localStorage.getItem("_token");
            const params = {
                user_id: localStorage.getItem("userId"),
                password: localStorage.getItem("password"),
                language: languageReducer.id,
                currency: currency.id,
                token: token,
            };

            const response = await axios.post("my-map", params);
            if (response.data.status === true) {
                setMapData(response.data.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            } else {
                setMapData([]);
                // errorMsg("Somthing went wrong...");
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            }else if (
                error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
              ) {
                navigate("/invalid-domain");
                window.history.pushState(null, "", "/invalid-domain");
              } else {
                errorMsg(error.response.data.message);
            }
        }
    };
};