import { successMsg } from "./customFn";
import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg } from "./customFn";
import { profileData } from "./profileAction";

export const becomeAffiliate = (
  languageReducer,
  navigate,
  become_user,
  formdata = {},
  setButtonDisable
) => {
  return async (dispatch) => {
    try {
      if (setButtonDisable) {
        setButtonDisable(true);
      }
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("password", localStorage.getItem("password"));
      formData.append("language", languageReducer.id);
      formData.append(
        "currency",
        JSON.parse(localStorage.getItem("currency")).id
      );
      formData.append("token", localStorage.getItem("_token"));
      formData.append("user_type", become_user);

      // Only append when user type is Partner
      if (become_user === "Partner") {
        formData.append("company_name", formdata.company_name);
        formData.append("company_address", formdata.company_address);
        formData.append("company_phone", formdata.company_phone);
        formData.append("company_email", formdata.company_email);
      }

      const response = await axios.post("become-affilliate", formData);

      if (response.data.status === true) {
        if (setButtonDisable) {
          setButtonDisable(false);
        }
        localStorage.setItem("user_type", become_user);
        successMsg(response.data.message);
        if (become_user == "Affiliate") {
          navigate("/affiliate-dashboard");
        }
        setTimeout(() => {
          if (become_user === "Affiliate") {
            dispatch(profileData(languageReducer, navigate, "Affiliate"));
          } else if (become_user === "Partner") {
            dispatch(profileData(languageReducer, navigate, "Partner"));
          }
        }, 1000);
      } else {
        if (setButtonDisable) {
          setButtonDisable(false);
        }
        if (response?.data?.message) {
          errorMsg(response?.data?.message);
        } else {
          errorMsg("Something went wrong...");
        }
      }
    } catch (error) {
      if (setButtonDisable) {
        setButtonDisable(false);
      }
      if (error?.response?.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Session expired. Please log in again.");
      }else if(error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE){
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else if (error?.response?.data?.message) {
        errorMsg(error?.response?.data?.message);
      } else {
        errorMsg("Something went wrong...");
      }
    }
  };
};
