import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, successMsg } from "./customFn";

//   ===================API call for guide starts=================
export const getContactUsData = (
  languageReducer,
  setContactUsData,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const params = {
        language: languageReducer.id,
        currency: currency.id,
      };

      const response = await axios.post("contact-us", params);
      if (response.data.status === true) {
        setContactUsData(response.data.data);
        setTimeout(() => {
          setIsLoading(true);
        }, 1000);
      } else {
        setTimeout(() => {
          setIsLoading(true);
        }, 1000);
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if(error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE){
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(function () {
    //     setIsLoading(true);
    // }, 1000);
  };
};

// ===================API call for guide ends===================
//   ===================API call for guide starts=================
export const contactUs = (languageReducer, formData, setFormData, navigate) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const params = {
        language: languageReducer.id,
        currency: currency.id,
        ...formData,
      };

      const response = await axios.post("send-contact", params);
      if (response.data.status === true) {
        setFormData({
          name: "",
          email: "",
          message: "",
        });
        successMsg(response.data.message);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if(error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE){
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(function () {
    //     setIsLoading(true);
    // }, 1000);
  };
};
// ===================API call for guide ends===================
