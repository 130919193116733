import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg } from "./customFn";

export const setProfile = (profileData) => {
  return {
    type: "PROFILE_DATA",
    payload: profileData,
  };
};

export const setUserType = (type) => {
  return {
    type: "USERTYPE",
    payload: type,
  };
};

//   ===================API call for profile starts=================
export const profileData = (
  languageReducer,
  navigate,
  becomeAffiliate = "",
  setLoggedInUser = ""
) => {
  return async (dispatch) => {
    try {
      // const token = localStorage.getItem("_token");
      const user_id = localStorage.getItem("userId");
      const password = localStorage.getItem("password");
      const currency = JSON.parse(localStorage.getItem("currency"));
      const userCredential = {
        user_id: user_id,
        password: password,
        language: languageReducer.id,
        currency: currency.id,
        // token: token,
      };

      const response = await axios.post("user-profile", userCredential);
      if (response.data.status == true) {
        dispatch(setProfile(response.data.data));
        dispatch(
          setUserType({
            user_type: response.data.data.user_type,
            code: response.data.data.code,
          })
        );
        if (setLoggedInUser) {
          setLoggedInUser(user_id);
        }
        if (becomeAffiliate == "Affiliate") {
          navigate("/affiliate-dashboard");
        } else if (becomeAffiliate == "Partner") {
          navigate("/dashboard");
        }
      } else {
        // Navigate("/");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        // errorMsg("Somthing went wrong...");
      } else if (
        error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
      ) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================API call for profile ends===================

export const getUserPolicy = async (languageReducer, setIsReviewModal) => {
  const user_id = localStorage.getItem("userId");
  const password = localStorage.getItem("password");
  const currency = JSON.parse(localStorage.getItem("currency"));
  const userCredential = {
    user_id: user_id,
    password: password,
    language: languageReducer.id,
    currency: currency.id,
  };

  const url = "/check-policy";
  await axios
    .post(url, userCredential)
    .then((response) => {
      const { data } = response;
      if (data.status) {
        if (data?.policy_read == 0) {
          setIsReviewModal(true);
        }
      }
    })
    .catch((error) => {});
};
