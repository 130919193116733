import axios from "../axios";
import { errorMsg, setGetToken, setMetaData } from "./customFn";
import { getLogout } from "./loginAction";

export const asyncGetProductDetails = async (
  id,
  languageReducer,
  navigate,
  setDetailsData,
  location,
  setIsLoading,
  setMetaData
) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  const currency = JSON.parse(localStorage.getItem("currency"));
  const data = {
    product_id: id,
    language: language,
    token: setGetToken(),
    currency: currency.id,
  };
  // const url = "/product-details";
  const url = "/product-details-new";
  await axios
    .post(url, data, options)
    .then((response) => {
      const { data } = response;
      if (data.status) {
        setDetailsData(data.data);
        setMetaData(data.data.meta_data);
      } else {
        navigate("/page-not-found");
      }
      setIsLoading(false);
    })
    .catch((error) => {
      if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      }else{
      navigate("/something-went-wrong");
      window.history.pushState(null, "", location.pathname);}
    });
};

// get Product Review List
export const getReviewList = async (
  id,
  languageReducer,
  setReviewData,
  setPageCount,
  currentPage,
  setIsLoading ,
  reviewCount,setReviewCount
) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  const currency = JSON.parse(localStorage.getItem("currency"));
  const data = {
    product_id: id,
    language: language,
    token: setGetToken(),
    currency: currency.id,
    offset: currentPage,
    count: reviewCount || 0,

  };
  // const url = "/product-details";
  const url = "/product-review-list";
  await axios
    .post(url, data, options)
    .then((response) => {
      const { data } = response;
      if (data.status) {
        setReviewData(data.data);
        setPageCount(response.data.page_count);
        setReviewCount(response.data.count);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
};

export const asyncGetAvailableOption = async (
  id,
  languageReducer,
  navigate,
  optionData,
  location,
  setAllOption,
  setFilterLoading,
  hideLoder
) => {
  if (!hideLoder) {
    setFilterLoading(true);
  }
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  const data = {
    product_id: id,
    language: language,
    token: setGetToken(),
    currency: 3,
    ...optionData,
  };

  const url = "/available-options";
  await axios
    .post(url, data, options)
    .then((response) => {
      const { data } = response;
      if (data.status) {
        setAllOption(data.data);
        setTimeout(() => {
          setFilterLoading(false);
        }, [1000]);
      } else {
        setTimeout(() => {
          setFilterLoading(false);
        }, [1000]);
        // navigate("/page-not-found");
      }
    })
    .catch((error) => {
      if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      }else{
      window.history.pushState(null, "", location.pathname);}
    });
  // setTimeout(() => {
  //   setFilterLoading(false);
  // }, [1000]);
};

export const genrateAffliateLink = (
  languageReducer,
  navigate,
  setAffiliateLink,
  setGenerateLink,
  getUrl,
  id,
  affilliate_code
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        tourId: id,
        affilliate_code: affilliate_code,
      };
      const response = await axios.post("generate_affiliate_link", params);
      if (response.data.status === true) {
        setAffiliateLink(getUrl);
        setGenerateLink(true);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================API call formy tours ends===================

export const getProductPreview = async (
  id,
  languageReducer,
  navigate,
  setDetailsData,
  location,
  setIsLoading,
  setMetaData,
  user_id = "",
  user_type = ""
) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  const currency = JSON.parse(localStorage.getItem("currency"));
  const token = localStorage.getItem("_token");

  if (user_id == "" && user_type == "") {
    user_id = localStorage.getItem("userId");
    user_type = localStorage.getItem("user_type");
  }
  const data = {
    product_id: id,
    language: language,
    token: token,
    currency: currency.id,
    user_id: user_id,
    user_type: user_type,
  };

  const url = "/product-preview";
  await axios
    .post(url, data, options)
    .then((response) => {
      const { data } = response;
      if (data.status) {
        setDetailsData(data.data);
        setMetaData(data.data.meta_data);
      } else {
        navigate("/page-not-found");
      }
      setIsLoading(false);
    })
    .catch((error) => {
      if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      }else{
      navigate("/something-went-wrong");
      window.history.pushState(null, "", location.pathname);}
    });
};
