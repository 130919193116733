import axios from "../axios";

export const setPhoneCode = (data) => {
    return {
        type: "PHONECODE_LIST",
        payload: data,
    };
};

export const getPhoneCodeList = () => {
    const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    return (dispatch) => {
        const url = "/phone_code";
        axios
            .post(url, options)
            .then((response) => {
                const { data } = response;
                if (data.status) {
                    // setPhoneCodeList(data.data)
                    dispatch(setPhoneCode(data.data));
                }
            })
            .catch((error) => {
                
                console.log(error);
            });
    };
};
