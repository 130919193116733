import axios from "../axios";
import { errorMsg, setGetToken } from "./customFn";

export const getAttractionListing = async (
  languageReducer,
  setListingData,
  navigate,
  setIsDataLoading,
  setPageCount,
  currentPage,
  attraction_slug,
  location,
  setIsLoading,
  setFormData,
  setProductCount,
  setPageData,
  exrtraData,
  setIntrestTitle,
  setBannerImage,
  setBannerTitle
) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  const currency = JSON.parse(localStorage.getItem("currency"));
  const data = {
    language: language,
    attraction_slug: attraction_slug,
    type: "",
    offset: currentPage,
    token: setGetToken(),
    currency: currency.id,
    ...setFormData,
    ...exrtraData,
  };

  const url = "/top-attraction-listing";
  await axios
    .post(url, data, options)
    .then((response) => {
      const { data } = response;

      if (data.status) {
        setPageCount(data.page_count);
        setListingData(data.data.list);
        setProductCount(data.product_count);
        setPageData(data.data.page_data);
        setIntrestTitle(data.intrest_title);
        setBannerImage(data.banner_image);
        setBannerTitle(data.thing_to_do);
        setTimeout(function () {
          setIsDataLoading(false);
          setIsLoading(true);
        }, 1000);
      } else {
        setTimeout(function () {
          setIsDataLoading(false);
          setIsLoading(true);
        }, 1000);
      }
    })
    .catch((error) => {
      if(error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE){
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      }else{
        errorMsg("Somethinng Went Wrong");
        navigate("/something-went-wrong");
        window.history.pushState(null, "", location.pathname);
      }
    });
};
