import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, successMsg } from "./customFn";

//   ===================API call for upcoming starts=================
export const getUpcomingBooking = (
  languageReducer,
  setUpcomingBooking,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
  filterData,
  isFilter
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
        is_filter: isFilter,
        ...filterData
      };

      const response = await axios.post("upcoming_booking", params);
      if (response.data.status === true) {
        setUpcomingBooking(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        setUpcomingBooking([])
        setRecordStatus(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (
        error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
      ) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000);
  };
};

//   ===================API call for upcoming starts=================
export const getUpcomingProviderOrders = (
  languageReducer,
  setUpcomingBooking,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
  filterData,
  isFilter,
  // apiQuery
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
        is_filter: isFilter,
        ...filterData
      };

      const response = await axios.post("provider-upcoming-orders", params);
      if (response.data.status === true) {
        setUpcomingBooking(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        setUpcomingBooking([])
        setRecordStatus(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (
        error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
      ) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000);
  };
};

// ===================API call for upcoming ends===================
//   ===================Provider Review starts=================
export const getProviderReviewList = (
  languageReducer,
  setReviewList,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
      };

      const response = await axios.post("supplier-review-list", params);
      if (response.data.status === true) {
        setReviewList(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        setReviewList([])
        setRecordStatus(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (
        error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE
      ) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      }else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================Provider Reviews ends===================
