import { useTransition } from "react";
import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";
import { profileData, setProfile } from "./profileAction";

export const setLogin = () => {
  return {
    type: "SET_LOGIN",
    payload: true,
  };
};

export const setLogout = () => {
  return {
    type: "SET_LOGOUT",
    payload: false,
  };
};

//LOgin
export const asyncLogin = (
  formData,
  resetForm,
  setButtonDisable,
  LogincloseModal,
  navigate,
  setFormErrors,
  errors,
  currentPath = "",
  redirectingUrl = "",
  setRedirectingUrl = "",
  setLoggedInUser
) => {
  return (dispatch) => {
    const url = "/login";
    axios
      .post(url, formData)
      .then((response) => {
        const data = response.data;
        if (data.status) {
          if (data.verified) {
            resetForm();
            localStorage.setItem("userId", data.user_id);
            localStorage.setItem("password", data.password);
            localStorage.setItem("user_type", data.user_type);
            setLoggedInUser(data.user_id);
            successMsg(data.message);
            dispatch(setLogin());
            LogincloseModal();
            if (redirectingUrl) {
              // In case if user comes from gamil
              navigate(`${redirectingUrl}`);
              setTimeout(() => {
                setRedirectingUrl("");
              }, 100);
            } else {
              if (
                currentPath !== "checkout" &&
                currentPath !== "activity-supplier"
              ) {
                //for this two routes the login model will closes at same page.
                if (data.user_type == "Partner") {
                  navigate("/dashboard");
                } else if (data.user_type == "Affiliate") {
                  navigate("/affiliate-dashboard");
                } else if (data.user_type == "Hotel") {
                  navigate("/hotel-user-dashboard");
                } else if (data.user_type == "Customer") {
                  navigate("/edit-profile");
                }
              }
            }
          } else {
            // localStorage.setItem("userId", data.user_id);
            // if (setButtonDisable !== "") {
            //   // setSignupbody("otp_modal");
            // }
            errorMsg(data.message);
            // setFormErrors(data.message)
          }
        } else {
          // errorMsg(data.message);
          errors.invalidCredentials = data.message;
          setFormErrors(errors);
        }
        setButtonDisable(false);
      })
      .catch((err) => {
        if (err?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE){
          navigate("/invalid-domain");
          window.history.pushState(null, "", "/invalid-domain");
        } else {
          LogincloseModal();
          setButtonDisable(false);
          navigate("/something-went-wrong");
          window.history.pushState(null, "", "/");
        }
      });
  };
};

//Signup
export const asyncSignup = (
  formData,
  resetForm,
  setButtonDisable,
  SetLoginKey,
  LogincloseModal,
  navigate,
  userType,
  openMessageModal = ""
) => {
  return (dispatch) => {
    const url = "/signup";
    axios
      .post(url, formData)
      .then((response) => {
        const data = response.data;
        if (data.status) {
          resetForm();
          // setShowModal(false);

          LogincloseModal();
          if (userType == "Partner") {
            openMessageModal();
          } else {
            if (data.user_type == "Customer") {
              localStorage.setItem("userId", data.user_id);
              localStorage.setItem("password", data.password);
              localStorage.setItem("user_type", data.user_type);
              dispatch(setLogin());
            }

            successMsg(data.message);
          }
        } else {
          errorMsg(data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 402) {
          errorMsg(err.response.data.message);
        } else if (err?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
          navigate("/invalid-domain");
          window.history.pushState(null, "", "/invalid-domain");
        } else {
          LogincloseModal();
          navigate("/something-went-wrong");
          window.history.pushState(null, "", "/");
        }
      });
    setButtonDisable(false);
  };
};

//Logout
export const getLogout = (navigate, t = "") => {
  return (dispatch) => {
    localStorage.removeItem("userId");
    localStorage.removeItem("password");
    localStorage.removeItem("user_type");

    dispatch(setLogout());
    dispatch(setProfile(""));
    navigate("/");
    if (t) {
      successMsg(t("_Logout_successfull"));
    }
  };
};

export const verifyEmailId = (
  email,
  setEmail,
  closeForgotPasswordModel,
  navigate,
  setButtonDisable,
  t,
  language_id
) => {
  return async (dispatch) => {
    try {
      const params = {
        email: email,
        language_id: language_id,
      };
      const response = await axios.post("verify-email", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        setEmail("");
        closeForgotPasswordModel();
      } else {
        errorMsg(response.data.message);
      }
      setButtonDisable(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate, t));
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
      setButtonDisable(false);
    }
  };
};

export const verifyLink = (id, navigate, setFormData, t) => {
  return async (dispatch) => {
    try {
      const params = {
        id: id,
      };
      const response = await axios.post("forget-verify-link", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        setFormData((prev) => ({ ...prev, email: response.data.email }));
      } else {
        errorMsg(response.data.message);
        navigate("/");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate, t));
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

export const resetPassword = (
  formData,
  setFormData,
  navigate,
  setModalLogin,
  t,
  setButtonDisable
) => {
  return async (dispatch) => {
    try {
      const params = {
        ...formData,
      };
      const response = await axios.post("reset-password", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        navigate("/");
        setModalLogin(true);
        setFormData({
          email: "",
          password: "",
          confirm_password: "",
        });
        setButtonDisable(false);
      } else {
        errorMsg(response.data.message);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate, t));
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

export const socialLogin = (
  loginData,
  LogincloseModal,
  navigate,
  currentPath = ""
) => {
  return async (dispatch) => {
    try {
      const params = {
        ...loginData,
      };
      const response = await axios.post("social-login", params);
      const data = response.data;
      if (data.status === true) {
        localStorage.setItem("userId", response.data.user_id);
        localStorage.setItem("password", response.data.password);
        localStorage.setItem("user_type", response.data.user_type);
        successMsg(response.data.message);
        dispatch(setLogin());
        LogincloseModal();
        // if (currentPath !== "/checkout") {
        //   navigate("/edit-profile");
        // }
        if (currentPath !== "checkout" && currentPath !== "activity-supplier") {
          //for this two routes the login model will closes at same page.
          if (data.user_type == "Partner") {
            navigate("/dashboard");
          } else if (data.user_type == "Affiliate") {
            navigate("/affiliate-dashboard");
          } else if (data.user_type == "Hotel") {
            navigate("/hotel-user-dashboard");
          } else if (data.user_type == "Customer") {
            navigate("/edit-profile");
          }
        }
      } else {
        errorMsg(response.data.message);
        LogincloseModal();
      }
    } catch (error) {
      if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        LogincloseModal();
        navigate("/something-went-wrong");
        window.history.pushState(null, "", "/");
      }
    }
  };
};

export const verifyUser = (id, navigate, openMessageModal) => {
  return async (dispatch) => {
    try {
      const params = {
        id: id,
        is_verified: true,
      };
      const response = await axios.post("forget-verify-link", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
      } else {
        openMessageModal();
        // errorMsg(response.data.message);
        // navigate("/");
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch(getLogout(navigate, t));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

export const deleteAccount = (navigate, languageReducer, t = "") => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
      };

      const response = await axios.post("delete-account", params);
      if (response.data.status === true) {
        localStorage.removeItem("userId");
        localStorage.removeItem("password");
        localStorage.removeItem("user_type");

        dispatch(setLogout());
        dispatch(setProfile(""));
        navigate("/");
        successMsg(response.data.message);
      } else {
        errorMsg(response.data.message);
        // navigate("/");
      }
    } catch (error) {
      if (error.response.status === 401) {
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      }else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
