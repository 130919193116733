import { Navigate } from "react-router-dom";
import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg } from "./customFn";

let abortController = new AbortController();
//   ===================Home Serach starts=================
export const homeSearch = (languageReducer, navigate, serachParameter, setSearchList, setSearchListLoder) => {
    return async (dispatch) => {
        try { 

            if (abortController) {
                abortController.abort();
            }

            abortController = new AbortController();

            const currency = JSON.parse(localStorage.getItem("currency"));
            const params = {
                language: languageReducer.id,
                currency: currency.id,
                term: serachParameter
            };

            const response = await axios.post("search-data", params,{
                signal: abortController.signal // Pass the signal to the request
            });
            if (response.data.status === true) {
                setSearchList(response.data.data);
                setSearchListLoder(false);
            } else {
                setSearchListLoder(false);
                errorMsg("Somthing went wrong...");
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
                navigate("/invalid-domain");
                window.history.pushState(null, "", "/invalid-domain");
              }
            // else if(error == "canceled"){

            // } else {
            //     alert(`${error}-${error?.response?.status}`);
            // }
        }
        // setTimeout(function () {
        //     setIsLoading(true);
        // }, 1000);
    };
};
// ===================Home Serach ends===================
