import { errorMsg, successMsg } from "./customFn";
import axios from "../axios";
import { getLogout } from "./loginAction";

//   ===================API call formy tours starts=================
export const getMyToursData = (
  languageReducer,
  setMyToursData,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
  filterData,
  isFilter
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: isFilter === 1 ? 0 : currentPage,
        is_filter: isFilter,
        ...filterData,
      };
      const response = await axios.post("my-tours", params);
      if (response.data.status === true) {
        setMyToursData(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
        setTimeout(function () {
          setIsLoading(false);
        }, 500);
      } else {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(function () {
    //   setIsLoading(false);
    // }, 500);
  };
};
// ===================API call formy tours ends===================

//   ===================API call formy tours starts=================
export const deleteToursData = (
  languageReducer,
  navigate,
  tourId,
  setToggle
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");

      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        tourId: tourId,
        // offset: currentPage,
      };

      const response = await axios.post("remove-tour", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        setToggle((state) => !state);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        // alert(`${error.message}-${error.response.status}`);
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================API call formy tours ends===================
export const getProductList = (
  languageReducer,
  navigate,
  setProductList
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
      };
      const response = await axios.post("my-tours", params);
      if (response.data.status === true) {
        setProductList(response.data.data);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      }else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(function () {
    //   setIsLoading(false);
    // }, 500);
  };
};

// Add New Category Or SubCategory

export const asyncAddNewCategoryOrSubCategory = async(
  formData
  ,languageReducer
) =>{

  try {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const token = localStorage.getItem("_token");
    const params = {
      ...formData,
      currency: currency,
      token: token,
      user_id: localStorage.getItem("userId"),
      password: localStorage.getItem("password"),
      language: languageReducer.id,
    };
    const response = await axios.post("add-new-inclusion-exclusion", params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      errorMsg("Somthing went wrong...");
    } else {
      errorMsg(error.response.data.message);
    }
  }
}