import { errorMsg } from "./customFn";
import { getLogout } from "./loginAction";
import axios from "../axios";

export const getHotelUserDashboardData = (
  languageReducer,
  setDashBoardData,
  navigate,
  setIsLoading,
  lastMonth,
  filter_month
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        last_month: lastMonth,
        filter_month: filter_month,
      };

      const response = await axios.post("hotel-dashboard", params);
      if (response.data.status === true) {
        setDashBoardData(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        errorMsg("Somthing went wrong...");
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

export const getHotelUserCommissionList = (
  languageReducer,
  setBookingHistory,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
  filterData,
  isFilter,
  hotel_slug
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
        is_filter: isFilter,
        hotel_slug: hotel_slug,
        ...filterData,
      };

      const response = await axios.post("hotel-commission-list", params);
      if (response.data.status === true) {
        setBookingHistory(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        setBookingHistory([]);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setRecordStatus(false);
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

export const getHotelCommissionDetails = (
  languageReducer,
  setOrderDetails,
  navigate,
  setIsLoading,
  id
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        id: id,
      };

      const response = await axios.post("hotel-commission-details", params);
      if (response.data.status === true) {
        setOrderDetails(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else if (error?.response?.status == process.env.REACT_APP_NOT_VALID_DOMAIN_CODE) {
        navigate("/invalid-domain");
        window.history.pushState(null, "", "/invalid-domain");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000);
  };
};
